import { DocumentType, SchemaType } from '@innedit/innedit-type';
import { SchemaData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';
import IconEye from '~/icons/Eye';
import IconEyeSlash from '~/icons/EyeSlash';

import ListItem from './index';

const ListItemSchema: FC<ListItemProps<SchemaType, SchemaData>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<SchemaType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      actions={[
        {
          icon: doc.hidden ? IconEyeSlash : IconEye,
          label: 'Changer la visibilité',
        },
      ]}
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemSchema;
